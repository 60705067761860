import React from 'react'

import Layout from '../layouts/offertLayout'
import {StyledHeader, StyledCategory, StyledContent} from '../layouts/layoutStyles'

import NaszeRealizacje from "../components/NaszeRealizacje"
import List_Element from '../components/ListElement'

import zdj from '../images/headerPhotos/dom.jpg'
import cienki from '../images/icons/cienki.png'
import gruby from '../images/icons/gruby.png'


const offert= ({data}) => {
  return(
    <Layout 
      headerPhoto={<img src={zdj} alt="naprawa"/>} 
      prefooterElement={<NaszeRealizacje/>}
    >
      <StyledHeader>DOM</StyledHeader>
      <StyledCategory>Beton polerowany</StyledCategory>
      <StyledContent>
        Wybór dla osób, które poszukują rozwiązania odpornego na zużycie oraz łatwego
        w utrzymaniu czystości. Jest wykonywany przy renowacjach hal, halach wystawowych
        oraz w pomieszczeniach sklepowych. Beton należy traktować jak minerał i jego
        kolorystyka zależy od naświetlenia, przewiewu i wilgotności powietrza oraz
        temperatury na zewnątrz i wewnątrz pomieszczenia. Z tego powodu, każda nasza
        posadzka ma <b>unikatowy</b> wygląd, a zdjęcia są jedynie sugestią jej finalnego wyglądu.

        <List_Element
          img={<img src={cienki} alt="cienki beton"/>}
          content={<span><b>Cienkowarstwowy</b> - jest on stosowany, na istniejącym podkładzie posadzkowym. Oznacza to, że nie musimy kuć istniejącego podkładu posadzki, a jedynie wylana zostaje cienka warstwa betonu (szarego, białego lub kolorowego, w zależności od zapotrzebowania klienta), która po wyschnięciu zostaje spolerowana. <b>Wylanie posadzki cienkowarstwowej nie wzmacnia podkładu posadzki.</b></span>}
        />
        <List_Element
          img={<img src={gruby} alt="cienki beton"/>}
          content={<span><b>Grubowarstwowy</b> - wylewka betonowa o minimalnej grubości 8 cm. Wylewana bezpośrednio na folię/styropian przygotowanego podłoża. Nie jest wykonywana na poprzednim podkładzie posadzkowym.</span>}
        />

      </StyledContent>
      <StyledCategory>Posadzka żywiczna</StyledCategory>
      <StyledContent>
        Wykonywana z żywicy epoksydowej i poliuretanowej. Jest droższa niż posadzka
        betonowa. Ten rodzaj posadzki jest niepylny i kwasodoporny. Często używana
        w miejscach o zaostrzonym rygorze sanitarnym np. w przemyśle gastronomicznym.
        Na odpowiednio przygotowaną powierzchnię rozprowadza się żywice. Nakładanie
        lakieru na posadzkę żywiczną zapewnia powierzchni matową, półmatową lub
        połyskującą strukturę.
      </StyledContent>
    </Layout>
  )
}

export default offert